<template>
  <v-file-input
    :class="`${className} file-input`"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errors"
    :flat="!isOutlined"
    :height="height"
    :hide-details="true"
    :label="placeholder"
    :outlined="!!errors.length || isOutlined"
    :solo="!isOutlined"
    accept="image/jpg,image/jpeg,image/png,image/heic, .pdf"
    light
    @blur="onBlur"
    @change="onChange"
    @focus="onFocus"
  >
    <template v-slot:append>
      <v-icon color="icon" size="16"> {{ appendIcon }}</v-icon>
    </template>
  </v-file-input>
</template>
<script>
export default {
  name: "FileInput",
  data: () => ({
    isOutlined: false,
  }),
  props: {
    className: { default: "", type: String },
    fieldName: String,
    height: { default: 47, type: Number },
    placeholder: { default: "", type: String },
    errors: { default: () => [], type: Array },
    appendIcon: { default: "", type: String },
    dense: { default: true, type: Boolean },
    disabled: { default: false, type: Boolean },
    fullWidth: { default: false, type: Boolean },
    type: { default: "", type: String },
    outlined: { default: false, type: Boolean },
  },

  methods: {
    onFocus() {
      this.isOutlined = true;
    },
    onChange(value) {
      this.$emit("change", value, this.fieldName);
      this.$emit("input", value);
    },
    onBlur() {
      if (!this.type) {
        this.isOutlined = false;
      }
      this.$emit("blur");
    },
  },
  mounted() {
    this.isOutlined = this.outlined;
  },
};
</script>
<style lang="scss" scoped>
.file-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
