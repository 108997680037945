<template>
  <div class="write-us">
    <span class="write-us_mail">
      {{ $t("general.contact.writeUs") }}
    </span>
    <div class="write-us_phone">
      <span class="write-us_phone_need-help">
        {{ $t("general.contact.needHelp") }}
      </span>
      <span class="write-us_phone_number">
        {{ telephoneNumber }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "WriteUs",
  data() {
    return {
      telephoneNumber: "1-(844)-984-2947",
    };
  },
};
</script>
<style lang="scss">
.write-us {
  display: flex;

  &_mail {
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.32px;
    color: rgba(51, 52, 62, 0.64);
    opacity: 0.8;
    margin-right: 24px;
  }

  &_phone {
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.32px;

    &_need-help {
      color: rgba(51, 52, 62, 0.36);
    }

    &_number {
      color: rgba(51, 52, 62, 1);
    }
  }
}
</style>
