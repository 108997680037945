<template>
  <div class="page-not-found">
    <img src="../../assets/waiting-man.svg" />
    <h1 class="heading-1">{{ $t("notFoundPage.head") }}</h1>
    <h2 class="heading-2">{{ $t("notFoundPage.title") }}</h2>
    <h3 class="sub-heading-1">
      <span v-html="$t('notFoundPage.subtitle', { contactUs })"></span>
    </h3>
    <v-btn outlined color="primary" @click="redirectLogin">{{ $t("notFoundPage.button") }}</v-btn>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
  props: {
    isHeader: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    contactUs() {
      const link = this.$t("notFoundPage.contactLink");
      const contactUs = this.$t("notFoundPage.contactUs");
      return `<a href="${link}">${contactUs}</a>`;
    },
  },
  methods: {
    async redirectLogin() {
      await this.$router.push({ path: "/auth/signin" });
    },
  },
};
</script>
