<template>
  <PatientSignUp />
</template>

<script>
import PatientSignUp from "@/components/PatientSignUp";
export default {
  name: "PatientSignUpPage",
  components: {
    PatientSignUp,
  },
};
</script>
