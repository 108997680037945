<template>
  <div class="p-sign-up_second-step">
    <v-row class="mb-4">
      <v-col class="p-sign-up_second-step_col" cols="6">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.firstName") }}
          <span class="star-required">*</span>
        </span>
        <BaseInput
          v-model="firstName"
          :errors="firstNameErrors"
          :placeholder="$t('general.inputs.firstName')"
          fieldName="firstName"
          @blur="$v.firstName.$touch()"
          @change="onInputChange"
        />
      </v-col>
      <v-col class="p-sign-up_second-step_col" cols="6">
        <span class="heading-5 text-left d-block pb-2">
          {{ $t("general.inputs.lastName") }}
          <span class="star-required">*</span>
        </span>

        <BaseInput
          v-model="lastName"
          :errors="lastNameErrors && validateFieldComputed('lastName', 'propErrors')"
          :placeholder="$t('general.inputs.lastName')"
          fieldName="lastName"
          @blur="$v.lastName.$touch()"
          @change="onInputChange"
        />
      </v-col>
    </v-row>
    <span class="heading-5 text-left d-block pb-2">
      {{ $t("general.inputs.dateOfBirth") }}
      <span class="star-required">*</span>
    </span>
    <DatePicker
      v-if="!loading"
      v-model="dateOfBirth"
      :dense="true"
      :errors="dateOfBirthErrors && validateFieldComputed('birthDate', 'propErrors')"
      :hide-details="true"
      :maxDate="new Date().toISOString().substr(0, 10)"
      :placeholder="$t('general.inputs.dateOfBirth')"
      class-name="mb-4"
      height="47"
      @blur="$v.dateOfBirth.$touch()"
    />
    <span class="heading-5 text-left d-block pb-2">
      Gender
      <span class="star-required">*</span>
    </span>
    <Switcher
      v-model="gender"
      :class-name="`mb-4 ${genderErrors?.length ? 'text-error' : ''}`"
      :items="$t('general.genders')"
      @change="onGenderChange"
    />
    <span class="heading-5 text-left d-block pb-2">
      {{ $t("general.inputs.address") }}
    </span>
    <BaseInput
      v-model="address"
      :persistentHint="true"
      :placeholder="$t('general.inputs.address')"
      class="mb-4"
      fieldName="address"
      @change="onInputChange"
    />
    <span class="heading-5 text-left d-block pb-2">
      {{ $t("general.inputs.city") }}
      <span class="star-required">*</span>
    </span>
    <v-autocomplete
      :errors="cityErrors"
      :items="predictionsValue"
      :label="$t('general.inputs.city')"
      :loading="isLoadingPredictions"
      :search-input.sync="predictionsSearch"
      :value="city"
      class="bd-grey mb-4 autocomplete"
      clearable
      fieldName="city"
      hide-details
      hide-no-data
      placeholder="Enter city"
      solo
      @change="onSelectPredictions"
    >
    </v-autocomplete>
    <span class="heading-5 text-left d-block pb-2">
      {{ $t("general.inputs.state") }}
      <span class="star-required">*</span>
    </span>
    <StateSelect
      v-model="selectedState"
      :errors="selectedStateErrors"
      class="mb-4"
      @blur="$v.selectedState.$touch()"
      @change="onStateChange"
    />
    <span class="heading-5 text-left d-block pb-2">
      Zip Code
      <span class="star-required">*</span>
    </span>
    <BaseInput
      v-model="postalCode"
      :errors="postalCodeErrors"
      class="mb-4"
      fieldName="postalCode"
      placeholder="Zip Code"
      @blur="$v.postalCode.$touch()"
      @change="onInputChange"
    />
    <span class="heading-5 text-left d-block pb-2"> Home phone number </span>
    <PhoneInput
      v-model="homePhoneNumber"
      :placeholder="'Home phone number'"
      class="mb-4"
      fieldName="homePhoneNumber"
      @change="onInputChange"
    />
    <span class="heading-5 text-left d-block pb-2">
      Mobile phone number
      <span class="star-required">*</span>
    </span>
    <PhoneInput
      v-model="phoneNumber"
      :errors="[...phoneNumberErrors, ...validateFieldComputed('phoneNumber', 'propErrors')]"
      class="mb-4"
      fieldName="phoneNumber"
      placeholder="Mobile phone number"
      @blur="$v.phoneNumber.$touch()"
      @change="onPhoneNumberChange"
    />

    <FileInput
      v-model="idFileDriverLicenseFront"
      class-name="mb-4 d-none"
      fieldName="idFileDriverLicenseFront"
      placeholder="Driver License ID (Front)"
      @change="onInputChange"
    />
    <FileInput
      v-model="idFileDriverLicenseBack"
      class-name="mb-4 d-none"
      fieldName="idFileDriverLicenseBack"
      placeholder="Driver License ID (Back)"
      @change="onInputChange"
    />
    <PrimaryButton
      :disabled="this.$v.$invalid"
      :full-width="true"
      :loading="isLoading"
      :text="$t('registration.step2.form.button')"
      color="success"
      @onClick="submit"
    />
  </div>
</template>
<script>
import { mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import PrimaryButton from "@/components/uikit/PrimaryButton";
import { validatorErrors } from "@/mixins/validatorErrors";
import { useInvitesStore } from "@/pinia-store/invites";
import { isValidPhoneNumber, isValidZip } from "@/utils/validations";

import DatePicker from "../shared/DatePicker";
import PhoneInput from "../shared/PhoneInput";
import StateSelect from "../shared/StateSelect";
import BaseInput from "../uikit/BaseInput";
import FileInput from "../uikit/FileInput";
import Switcher from "../uikit/Switcher";

const googleAutocompleteService = new window.google.maps.places.AutocompleteService();

export default {
  name: "PatientSignUpSecondStepForm",
  mixins: [validatorErrors],
  props: ["propErrors", "isLoading"],
  components: {
    PrimaryButton,
    DatePicker,
    BaseInput,
    PhoneInput,
    StateSelect,
    Switcher,
    FileInput,
  },
  validations: {
    selectedState: { required },
    dateOfBirth: { required },
    firstName: { required },
    lastName: { required },
    gender: { required },
    city: { required },
    phoneNumber: { required, isValidPhoneNumber },
    postalCode: { required, isValidZip },
  },
  data: () => ({
    loading: true,
    selectedState: "",
    dateOfBirth: null,
    firstName: "",
    lastName: "",
    homePhoneNumber: "",
    phoneNumber: "",
    gender: undefined,
    address: "",
    city: "",
    isLoadingPredictions: false,
    predictionsSearch: "",
    postalCode: "",
    predictions: [],
    primaryPhone: "",
    primaryPhoneType: "",
    idFileDriverLicenseFront: {},
    idFileDriverLicenseBack: {},
  }),
  watch: {
    predictionsSearch(val) {
      if (!val || val === this.name) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        const displaySuggestions = (predictions, status) => {
          if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return;
          }
          this.predictionsResults = predictions;
          this.predictions = predictions.map((pr) => pr?.terms?.[0].value || "");
        };
        googleAutocompleteService.getPlacePredictions({ input: val, types: ["(cities)"] }, displaySuggestions);
      }, 300);
    },
  },
  computed: {
    ...mapState(useInvitesStore, ["invite"]),
    predictionsValue() {
      return this.city ? [...this.predictions, this.city] : this.predictions;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    selectedStateErrors() {
      const errors = [];
      if (!this.$v.selectedState.$dirty) return errors;
      !this.$v.selectedState.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.state") }));
      return errors;
    },
    dateOfBirthErrors() {
      const errors = [];
      if (!this.$v.dateOfBirth.$dirty) return errors;
      !this.$v.dateOfBirth.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.dateOfBirth") }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      (!this.$v.phoneNumber.required || !isValidPhoneNumber(this.phoneNumber)) &&
        errors.push(this.$t("validation.required", { field: "Mobile phone number" }));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push(this.$t("validation.required", { field: this.$t("general.inputs.city") }));
      return errors;
    },
    postalCodeErrors() {
      const errors = [];
      if (!this.$v.postalCode.$dirty) return errors;
      (!this.$v.postalCode.required || !this.$v.postalCode.isValidZip) &&
        errors.push(this.$t("validation.required", { field: "Zip Code" }));
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;

      if (!this.$v.gender.required) errors.push(this.$t("validation.required", { field: "Gender" }));
      return errors;
    },
  },

  methods: {
    onSelectPredictions(selectedValue) {
      const item = this.predictionsResults.find((result) => result?.terms?.[0].value === selectedValue);
      if (item) {
        this.city = item?.terms?.[0].value || "";
        this.$v.city.$touch();
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit("checkoutStep", 3);
      this.$emit("saveValues", this._data);
    },
    onInputChange(value, fieldName) {
      this[fieldName] = value;
    },
    onPhoneNumberChange(value, fieldName) {
      this[fieldName] = value;
      this.$v.phoneNumber.$touch();
    },
    onStateChange(state) {
      this.selectedState = state;
    },
    onGenderChange(gender) {
      this.gender = gender;
      this.$v.gender.$touch();
    },
  },
  mounted() {
    this.firstName = this.invite.firstName || "";
    this.lastName = this.invite.lastName || "";
    this.phoneNumber = this.invite.phoneNumber || "";
    this.email = this.invite.email || "";
    if (this.invite?.birthDate) {
      this.dateOfBirth = this.invite.birthDate;
    }

    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
};
</script>
<style lang="scss">
.v-input__control {
  .v-input__slot {
    padding: 4px 12px 2px 12px;
  }
}

.p-sign-up_second-step {
  .autocomplete {
    .v-input__slot {
      box-shadow: none !important;
    }
  }

  .v-input__slot {
    border: 1px solid $primarylight2;
  }

  &_select {
    .theme--light.v-icon {
      color: $icon;
    }
  }

  &_btn {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem !important;
    line-height: 20px;

    &__disabled {
      color: $primaryblack;

      .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $primarylight !important;
      }
    }
  }

  &_col {
    padding-top: 10px;
    padding-bottom: 0;
  }

  &_btn-toggle {
    height: 47px;
    margin-bottom: 29px;

    &_btn {
      flex: 0 0 34%;
    }
  }
}
</style>
