<template>
  <v-form v-on:submit.prevent="submit" novalidate class="p-sign-up_first-step">
    <BaseInput :placeholder="email" :disabled="true" class="mb-4" />
    <PasswordInput
      v-model="password"
      :isPasswordHelper="true"
      :passwordRootChecks="passwordChecks"
      :errors="passwordErrors"
      :placeholder="$t('general.inputs.password')"
      @blur="$v.password.$touch()"
      @change="onPasswordChange"
      className="mb-4"
      :hideDetails="false"
    />
    <PasswordInput
      v-model="confirmedPassword"
      :placeholder="$t('general.inputs.confirmPassword')"
      :errors="confirmedPasswordErrors"
      @blur="$v.confirmedPassword.$touch()"
      className="mb-4"
      @change="onConfirmedPasswordChange"
      :hideDetails="false"
    />
    <div class="consent-wrap mb-3">
      <v-checkbox v-model="consent" dense class="checkbox" hide-details />
      <div>
        {{ $t("registration.step1.form.inputs.consent") }}
        <router-link to="/consent" target="_blank">{{ $t("registration.step1.form.inputs.treatment") }}</router-link
        >,
        <router-link to="/terms" target="_blank">{{ $t("registration.step1.form.inputs.terms") }}</router-link>
      </div>
    </div>
    <v-btn
      type="submit"
      depressed
      large
      width="100%"
      color="success"
      :class="{
        'p-sign-up_first-step_btn': true,
        'p-sign-up_first-step_btn__disabled': this.$v.$invalid,
      }"
      :light="this.$v.$invalid"
      :dark="!this.$v.$invalid"
      :disabled="this.$v.$invalid"
    >
      {{ $t("registration.step1.form.button") }}
    </v-btn>
  </v-form>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import PasswordInput from "../shared/PasswordInput";
import BaseInput from "../uikit/BaseInput";
const passwordMatch = (value, vm) => {
  return value === vm.password;
};
const passwordValid = (value, vm) => {
  return vm.passwordChecks.some((pc) => pc.value);
};
const passwordMinLength = (value) => {
  return value.length >= 6;
};
export default {
  name: "PatientSignUpFirstStepForm",
  props: {
    email: String,
  },
  validations: {
    password: { required, passwordValid, passwordMinLength },
    confirmedPassword: { required, passwordMatch },
    consent: { required, checked: (v) => !!v },
  },
  components: {
    PasswordInput,
    BaseInput,
  },
  data: () => ({
    password: "",
    confirmedPassword: "",
    showPassword: false,
    showConfirmedPassword: false,
    showPasswordHelper: false,
    consent: null,
  }),
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      !this.$v.password.passwordMinLength && errors.push(this.$t("validation.passwordMinLength"));
      !this.$v.password.passwordValid && errors.push(this.$t("validation.passwordMustApply"));
      return errors;
    },
    confirmedPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmedPassword.$dirty) return errors;
      !this.$v.confirmedPassword.passwordMatch && errors.push(this.$t("validation.passwordMatch"));
      !this.$v.confirmedPassword.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.password") }));
      return errors;
    },
    passwordChecks() {
      return [
        {
          value: this.password.length >= 8,
          text: this.$t("settings.changePassword.hint.length"),
          id: "characters",
        },
        {
          value: /(?=.*[a-z])(?=.*[A-Z])/.test(this.password),
          text: this.$t("settings.changePassword.hint.uppercase"),
          id: "letters",
        },
        {
          value: /(?=.*\d)/.test(this.password),
          text: this.$t("settings.changePassword.hint.number"),
          id: "digits",
        },
      ];
    },
  },
  methods: {
    submit() {
      this.$emit("checkoutStep", 2);
      this.$emit("saveValue", this.password);
    },
    onPasswordChange(password) {
      this.password = password;
    },
    onConfirmedPasswordChange(password) {
      this.confirmedPassword = password;
    },
  },
};
</script>
<style lang="scss">
.p-sign-up_first-step {
  &_btn {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem !important;
    line-height: 20px;

    &__disabled {
      color: $primaryblack;
    }
  }
}
.consent-wrap {
  display: flex;
  align-items: center;
  color: #888;
  .checkbox {
    margin-top: 0 !important;
    font-size: 14px;
  }
}
.consent-checkbox {
  margin: 0 0 10px;
  font-size: 14px;
}
</style>
